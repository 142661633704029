import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss'],
})
export class FilterSelectComponent implements OnInit {
  @Input() title: string = '';
  @Input() taxonomy: string = '';
  @Input() terms: any[] = [];
  @Input() options: any[] = [];
  @Input() multi: boolean = false;
  @Input() placeholder: string = 'Select';
  @Input() view: string = 'pill';

  @Output() update = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  select(term: any) {
    if (this.multi && this.view == 'drop-down') {
      this.terms.forEach((t) => {
        t.selected = false;
        if (t.filteredCount! > 0) {
          t.disabled = true;
        }
      });

      term.forEach((selected: any) => {
        if (this.terms.some((e) => e.value === selected.value)) {
          selected.selected = true;
        }
      });

      this.terms = this.terms.filter((t) => t.filteredCount > 0);
    } else {
      term.selected = !term.selected;
      // unselect others automatically
      this.terms.forEach((t) => {
        if (t.value != term.value) {
          t.selected = false;
        }
      });
    }

    // this.update.emit();
    this.update.emit(term);
  }
}
