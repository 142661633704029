import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from '@components/home/home.component';
import { DataComponent } from '@components/data/data.component';
import { ProductComponent } from '@components/product/product.component';
import { HygieneComponent } from '@components/hygiene/hygiene.component';
import { PackagingComponent } from './components/packaging/packaging.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'trays',
    component: PackagingComponent,
  },
  {
    path: 'hygiene',
    component: HygieneComponent,
  },
  {
    path: 'hygiene/:type',
    component: HygieneComponent,
  },
  {
    path: 'product/:type/:id',
    component: ProductComponent,
  },
  {
    path: 'data',
    component: DataComponent,
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
