<app-navigation></app-navigation>
<header>
  <div
    class="container-xl d-flex flex-wrap justify-content-center justify-content-md-between align-items-center h-100"
  >
    <h2 class="d-none d-md-inline-block text-white">
      Our Food Hygiene Products
    </h2>
    <div class="position-relative float-end">
      <input
        class="form-control rounded-pill form-control-lg w-100"
        type="text"
        placeholder="Search"
        aria-label=""
        name="searchTerm"
        [(ngModel)]="hygieneService.hygieneSearchTerm"
        style="padding-left: 50px"
      />
      <fa-icon
        [icon]="faMagnifyingGlass"
        class="position-absolute _text-primary"
        style="
          font-size: 1.1rem;
          top: -1px;
          margin-left: 20px;
          margin-top: 13px;
        "
      >
      </fa-icon>

      <span
        style="right: 12px; top: 9px"
        class="position-absolute"
        *ngIf="hygieneService.hygieneSearching$ | async"
      >
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </span>
    </div>
  </div>
</header>

<div class="container-xl">
  <app-tabs
    [tabs]="tabs"
    [selected]="selectedTab"
    (tabChanged)="handleTabChanged($event)"
  ></app-tabs>
  <div class="row justify-content-center">
    <div
      class="col-auto col-md-3 col-xl-2 _px-sm-2 px-0 _bg-light"
      style="_border-right: 1px solid rgba(0, 0, 0, 0.125)"
    >
      <div
        class="d-flex flex-column align-items-center align-items-sm-start _px-4 _pt-4 _text-white"
      >
        <!-- <button
          *ngIf="!(hygieneService.hygieneLoading$ | async)"
          class="float-end btn btn-primary text-white _w-100 mb-0 mt-4 mx-3"
          (click)="clearFilters()"
        >
          Clear Filters
        </button> -->
        <app-filter
          *ngIf="showSexFilters"
          [subFilter]="sexFilters"
          [type]="'hygiene'"
          class="w-100 d-none d-sm-block"
          (getSelection)="getSelection($event)"
        ></app-filter>
        <app-filter
          *ngIf="(hygieneService.hygieneTotalFiltered$ | async)! > 0"
          [filter]="categoryFilters"
          [type]="'hygiene'"
          class="w-100 d-none d-sm-block"
          (getSelection)="getSelection($event)"
        ></app-filter>

        <app-filter
          *ngIf="showSubCat1"
          [subFilter]="subCat1"
          [type]="'hygiene'"
          class="w-100 d-none d-sm-block"
          (getSelection)="getSelection($event)"
        ></app-filter>
        <app-filter
          *ngIf="showSubCat2"
          [subFilter]="subCat2"
          [type]="'hygiene'"
          class="w-100 d-none d-sm-block"
          (getSelection)="getSelection($event)"
        ></app-filter>
        <app-filter
          *ngIf="showSubCat3"
          [subFilter]="subCat3"
          [type]="'hygiene'"
          class="w-100 d-none d-sm-block"
          (getSelection)="getSelection($event)"
        ></app-filter>

        <app-filter
          *ngIf="showColourFilters"
          [subFilter]="colourFilters"
          [type]="'hygiene'"
          class="w-100 d-none d-sm-block"
          (getSelection)="getSelection($event)"
        ></app-filter>
      </div>
    </div>
    <div class="col pt-5">
      <div
        *ngIf="hygieneService.hygieneLoading$ | async"
        class="spinner-border text-primary"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>

      <div class="d-flex w-100 justify-content-between">
        <h5
          class="text-secondary"
          *ngIf="
            !(hygieneService.hygieneLoading$ | async) &&
            (hygieneService.hygieneTotalFiltered$ | async)! > 0
          "
        >
          Showing
          {{
            hygieneService.pageSize * hygieneService.page -
              hygieneService.pageSize +
              1
          }}
          -
          {{
            hygieneService.pageSize * hygieneService.page >
            (hygieneService.hygieneTotalFiltered$ | async)!
              ? (hygieneService.hygieneTotalFiltered$ | async)
              : hygieneService.pageSize * hygieneService.page
          }}
          of
          {{ hygieneService.hygieneTotalFiltered$ | async }}
          product<ng-container
            *ngIf="(hygieneService.hygieneTotalFiltered$ | async) !== 1"
            >s</ng-container
          >
        </h5>
        <h5
          class="text-secondary"
          *ngIf="
            !(hygieneService.hygieneLoading$ | async) &&
            (hygieneService.hygieneTotalFiltered$ | async)! === 0
          "
        >
          No products found
          <ng-container *ngIf="returnTabsLabelsWithCounts().length !== 0"
            >for "{{ hygieneService.hygieneSearchTerm }}" in
            {{ returnCurrentTab()?.label }} but
            <strong>{{ returnTabsLabelsWithCounts() }}</strong> might have what
            you're looking for.</ng-container
          >
          <ng-container *ngIf="returnTabsLabelsWithCounts().length === 0">
            for "{{ hygieneService.hygieneSearchTerm }}".
          </ng-container>
        </h5>
        <div *ngIf="!(hygieneService.hygieneLoading$ | async)" class="d-flex">
          <select
            class="form-select form-select-sm me-2"
            style="width: auto; height: 31px"
            name="pageSize"
            [(ngModel)]="hygieneService.pageSize"
          >
            <option [ngValue]="25">25</option>
            <option [ngValue]="50">50</option>
            <option [ngValue]="100">100</option>
          </select>
          <ngb-pagination
            [collectionSize]="(hygieneService.hygieneTotalFiltered$ | async)!"
            [(page)]="hygieneService.page"
            [pageSize]="hygieneService.pageSize"
            size="sm"
          >
          </ngb-pagination>
        </div>
      </div>
      <div>
        <ng-container
          *ngFor="let filter of hygieneService.hygieneFilter$ | async"
        >
          <ng-container *ngFor="let term of filter.terms">
            <span
              class="badge rounded-pill bg-primary me-2 p-2 px-2 ps-3"
              *ngIf="term.selected && term.key !== 'product_group'"
              >{{ term.value }}
              <span
                style="background-color: white; padding: 3px 4px"
                class="ms-2 text-primary d-inline-block ms-2 rounded-circle cursor"
                (click)="
                  term.selected = !term.selected; hygieneService.filterChanged()
                "
                >X</span
              ></span
            >
          </ng-container>
        </ng-container>
      </div>

      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-5d mt-4">
        <div
          *ngFor="let product of hygieneService.hygieneProducts$ | async"
          class="col mb-5"
        >
          <div
            class="card h-100 cursor"
            [routerLink]="['/product/hygiene/', product.code]"
          >
            <div
              class="card-body p-4"
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
              "
            >
              <h5 class="card-title text-center">{{ product.name }}</h5>

              <img
                [src]="product['image']"
                class="productImg"
                style="background-color: #fff; border-top-width: auto"
                appHideMissing
              />

              <div class="_ps-4">
                <small>
                  <ul class="list-unstyled mt-3">
                    <li
                      class="d-flex justify-content-center align-items-center"
                      *ngIf="product.variants[0].colour !== ''"
                    >
                      <ng-container *ngIf="product.availableColours">
                        <div
                          style="
                            margin-left: 1rem;
                            display: flex;
                            gap: 1rem;
                            flex-wrap: wrap;
                            justify-content: center;
                          "
                        >
                          <div *ngFor="let colour of product.availableColours">
                            <div
                              class="colourBtn {{
                                colour
                                  .toLowerCase()
                                  .replace('/', '')
                                  .replace(' ', '')
                              }}"
                              (mouseover)="
                                changeImage(product.variants, colour)
                              "
                            ></div>
                          </div>
                        </div>
                      </ng-container>
                    </li>
                    <li>
                      <p class="text-primary mt-3 text-center">
                        {{ product.category }} - {{ product.name }}
                      </p>
                    </li>
                  </ul>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="card border-0"
        *ngIf="!(hygieneService.hygieneLoading$ | async)"
      >
        <div class="card-body bg-light text-center py-5">
          <h2 class="text-primary">Looking for something specific?</h2>
          <a
            href="mailto:packaging.sales@dbm-ltd.co.uk"
            class="btn btn-lg btn-primary text-white"
            >Contact Us</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
