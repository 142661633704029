import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HygieneService } from '@app/services/hygiene.service';
@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Input() tabs: { key: string; label: string; count: number }[] = [];
  @Input() selected: string = '';
  @Output() tabChanged = new EventEmitter<{
    key: string;
    label: string;
    count: number;
  }>();

  constructor(public hygieneService: HygieneService) {}

  ngOnInit(): void {}

  handleTabChanged(tab: { key: string; label: string; count: number }) {
    this.tabChanged.emit(tab);
  }
}
