import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HomeComponent } from '@components/home/home.component';
import { FilterComponent } from '@components/filter/filter.component';
import { FilterSelectComponent } from './components/filter-select/filter-select.component';
import { FormsModule } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { DataComponent } from '@components/data/data.component';
import { NavigationComponent } from '@components/navigation/navigation.component';
import { ProductComponent } from '@components/product/product.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HideMissingDirective } from './directives/hide-missing.directive';

import { NgSelectModule } from '@ng-select/ng-select';
import { HygieneComponent } from '@components/hygiene/hygiene.component';
import { PackagingComponent } from './components/packaging/packaging.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FilterComponent,
    FilterSelectComponent,
    DataComponent,
    NavigationComponent,
    PackagingComponent,
    ProductComponent,
    HideMissingDirective,
    HygieneComponent,
    TabsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    NgSelectModule,
    NgbModule,
  ],
  providers: [DecimalPipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
