<!-- <div class="card w-100 _mb-4" style="width: 18rem;">
  <div class="card-header bg-secondary text-white px-3 py-2">
    {{ title }}
  </div>

  
</div> -->
<!-- <pre>{{ terms | json }}</pre> -->
<div
  class="_bg-secondary text-uppercase _text-white px-2 mt-3 mb-2"
  style="color: #aaa"
>
  <span *ngIf="view !== 'inline'" style="font-weight: 500">{{ title }}</span>
</div>

<ng-container *ngIf="view == 'list'">
  <ul class="list-group list-group-striped _list-group-flush w-100">
    <ng-container *ngFor="let term of terms">
      <li
        *ngIf="term.filteredCount > 0"
        class="list-group-item cursor px-3 py-2"
        [class.bg-primary]="term.selected"
        [class.text-white]="term.selected"
        (click)="select(term)"
      >
        <small
          >{{ term.value }}<span class="text-muted float-end"></span
        ></small>
        <span class="float-end badge rounded-pill bg-primary">{{
          term.filteredCount
        }}</span>
      </li>
    </ng-container>
  </ul>
</ng-container>

<ng-container *ngIf="view == 'pill'">
  <div>
    <ng-container *ngFor="let term of terms">
      <div
        *ngIf="term.filteredCount > 0"
        class="cursor border rounded-pill d-inline-block me-2 mb-2 px-2"
        [class.bg-primary]="term.selected"
        [class.bg-light]="!term.selected"
        [class.text-white]="term.selected"
        (click)="select(term)"
      >
        <div class="p-1">
          {{ term.value }}
          <span class="text-primary"
            ><small>({{ term.filteredCount }})</small></span
          >
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="view == 'inline'">
  <div class="buttonGroup" role="group" aria-label="Basic example">
    <button
      type="button"
      [class.selected]="term.selected"
      *ngFor="let term of terms"
      (click)="select(term)"
    >
      <small>{{ term.value }}</small>
    </button>
  </div>
</ng-container>

<ng-container *ngIf="view == 'drop-down'">
  <ng-select
    [items]="terms"
    bindLabel="value"
    [placeholder]="placeholder"
    [searchable]="false"
    [multiple]="multi"
    [hideSelected]="true"
    (change)="select($event)"
  >
    <ng-option *ngFor="let item of terms" [value]="item">
      <ng-template ng-option-tmp let-item="item" *ngIf="item.filteredCount > 0">
        <span>{{ item.value }} - {{ item.filteredCount }}</span>
        <span class="float-end badge rounded-pill bg-primary">{{
          item.filteredCount
        }}</span>
      </ng-template>
    </ng-option>
  </ng-select>
</ng-container>
