import { Component, OnInit } from '@angular/core';
import { SubFilter } from '@app/models/subFilter';
import { HygieneService } from '@app/services/hygiene.service';
import { ProductService } from '@app/services/product.service';
import {
  faMagnifyingGlass,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from '@fortawesome/pro-regular-svg-icons';
import { Variant } from '@app/models/hygieneCatalog';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { environment } from '@env/environment';
@Component({
  selector: 'app-hygiene',
  templateUrl: './hygiene.component.html',
  styleUrls: ['./hygiene.component.scss'],
})
export class HygieneComponent implements OnInit {
  faMagnifyingGlass = faMagnifyingGlass;
  faAngleDoubleLeft = faAngleDoubleLeft;
  faAngleDoubleRight = faAngleDoubleRight;
  categoryFilters: any[] = [];
  subCat1!: SubFilter;
  subCat2!: SubFilter;
  subCat3!: SubFilter;
  sexFilters!: SubFilter;
  colourFilters!: SubFilter;
  showColourFilters: boolean = false;
  showSexFilters: boolean = false;
  showSubCat1: boolean = false;
  showSubCat2: boolean = false;
  showSubCat3: boolean = false;
  productGroupFilters: any[] = [];
  products: any;

  tabs: { key: string; label: string; count: number }[] = [
    { key: 'chemicals', label: 'Chemicals', count: 0 },
    { key: 'first-aid', label: 'First Aid', count: 0 },
    { key: 'footwear', label: 'Footwear', count: 0 },
    { key: 'handcare', label: 'Handcare', count: 0 },
    { key: 'janitorial', label: 'Janitorial', count: 0 },
    { key: 'ppe', label: 'PPE', count: 0 },
    { key: 'clothing', label: 'Clothing', count: 0 },
  ];
  selectedTab: string = '';
  currentPage = 1;
  itemsPerPage = 30;
  startPage = 0;
  endPage = 10;
  currentNumberOfProducts = 0;

  constructor(
    public hygieneService: HygieneService,
    public productService: ProductService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('selectedTab') !== null) {
      this.router.navigate(['/hygiene/' + localStorage.getItem('selectedTab')]);
    } else {
      this.router.navigate(['/hygiene/chemicals']);
    }

    if (localStorage.getItem('pageSize') !== null) {
      this.hygieneService.pageSize = parseInt(
        localStorage.getItem('pageSize') || '25'
      );
    }
    this.hygieneService.hygieneProducts$.subscribe((result) => {
      this.products = result;
      this.currentNumberOfProducts = this.products.length;
      this.products.forEach((product: any) => {
        product.image =
          environment.siteUrl +
          `/assets/images/hygiene/${
            product.variants[0].daysix_sub_product_code.length > 0
              ? product.variants[0].daysix_sub_product_code.replace('/', '')
              : product.variants[0].dbm_product_code.replace('/', '')
          }.jpg`;

        product.availableUnits = product.variants.reduce(
          (acc: string[], variant: Variant) => {
            if (variant.unit_of_sale !== 'Each') {
              acc.push(' ' + variant.unit_of_sale);
            }
            return acc;
          },
          []
        );
        product.availableColours = product.variants.reduce(
          (acc: string[], variant: Variant) => {
            if (!acc.includes(variant.colour.toLowerCase())) {
              acc.push(variant.colour.toLowerCase());
            }
            return acc;
          },
          []
        );
      });

      const tab = this.tabs.find(
        (t) => t.key === this.route.snapshot.paramMap.get('type')
      );

      this.hygieneService.hygieneLoading$.subscribe((result) => {
        if (!result) {
          this.tabs.forEach((tab) => {
            tab.count = this.hygieneService.resultsInEachCategory.find(
              (r: { product_group: string }) => r.product_group === tab.label
            )?.count;
          });

          if (this.selectedTab === '') {
            this.hygieneService.setSelectedProductGroup(
              tab && tab.label ? tab.label : 'Chemicals'
            );
            this.selectedTab = tab && tab.key ? tab.key : 'chemicals';
          } else if (this.selectedTab === 'clothing') {
            this.showSexFilters = true;
          }
        }
      });
    });

    this.hygieneService.hygieneFilter$.subscribe((result) => {
      this.categoryFilters = result.filter((f: any) => f.key === 'category');
      this.subCat1 = result.find((f: any) => f.key === 'sub_cat_1');
      this.subCat2 = result.find((f: any) => f.key === 'sub_cat_2');
      this.subCat3 = result.find((f: any) => f.key === 'sub_cat_3');
      this.sexFilters = result.find((f: any) => f.key === 'sex');
      this.colourFilters = result.find((f: any) => f.key === 'colours');
      this.hygieneService.hygieneLoading$.subscribe((result) => {
        if (!result) {
          if (this.colourFilters) {
            this.showColourFilters = this.colourFilters.terms.some(
              (term: any) => term.filteredCount > 0
            );
          }
          if (this.sexFilters) {
            this.showSexFilters = this.sexFilters.terms.some(
              (term: any) => term.filteredCount > 0
            );
          }
        }
      });

      this.productGroupFilters = result.filter(
        (f: any) => f.key === 'product_group'
      );
    });
  }
  changeImage(variants: any, colour: string) {
    const variantImage = variants.find(
      (v: any) => v.colour.toLowerCase() === colour
    );
    const product = this.products.find(
      (p: any) => p.daysix_product_code === variantImage.daysix_product_code
    );
    product.image =
      environment.siteUrl +
      `/assets/images/hygiene/${
        variantImage.daysix_sub_product_code.length > 0
          ? variantImage.daysix_sub_product_code.replace('/', '')
          : variantImage.product_code.replace('/', '')
      }.jpg`;
  }

  getSelection(option: any) {
    if (option.key === 'product_group') {
      this.hygieneService.clearSubFilters(option);
    }
    if (option.key === 'category') {
      if (this.subCat1.terms.filter((t: any) => t.filteredCount > 0)) {
        this.showSubCat1 = true;
      }
    }
    if (option.key === 'sub_cat_1') {
      if (this.subCat2.terms.find((t: any) => t.filteredCount > 0)) {
        this.showSubCat2 = true;
      }
    }
    if (option.key === 'sub_cat_2') {
      if (this.subCat3.terms.find((t: any) => t.filteredCount > 0)) {
        this.showSubCat3 = true;
      }
    }
  }
  clearFilters() {
    this.hygieneService.clearFilter('category');
    this.showSubCat1 = false;
    this.showSubCat2 = false;
    this.showSubCat3 = false;
  }

  handleTabChanged = (tab: { key: string; label: string }): void => {
    this.getSelection(tab);
    this.selectedTab = tab.key;
    this.clearFilters();
    this.hygieneService.setSelectedProductGroup(tab.label);
    localStorage.setItem('selectedTab', tab.key);
    this.router.navigate(['/hygiene/' + tab.key]);
  };
  returnCurrentTab() {
    return this.tabs.find((t) => t.key === this.selectedTab);
  }
  returnTabsLabelsWithCounts() {
    return this.tabs
      .filter((t) => t.count > 0)
      .map((t) => t.label)
      .join(', ')
      .replace(/,(?!.*,)/gim, ' or');
  }
}
