<app-navigation></app-navigation>

<div class="p-5 mt-5">

  <div *ngIf="errors" class="alert alert-danger p-4" role="alert">

    <h4 class="alert-heading mb-3">Parse Errors</h4>
    <table class="table table-borderless small">
      <tbody>
        <tr *ngFor="let error of errors">
          <td width="5"><strong>{{ error.row+1 }}</strong></td>
          <td>{{ error.message }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <table *ngIf="data && headers" class="table table-striped small">
    <thead>
      <tr>
        <th>#</th>
        <th scope="col" *ngFor="let header of headers | keyvalue:keepOriginalOrder">{{
          header.value }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let obj of data; let i = index" [class.bg-danger-light]="hasErrors(i)">
        <td>{{ i+1 }}</td>
        <td *ngFor="let o of obj | keyvalue:keepOriginalOrder">{{ o.value }}</td>
      </tr>
    </tbody>
  </table>

</div>