import { Directive, ElementRef, HostListener } from '@angular/core';
import { environment } from '@env/environment';

@Directive({
  selector: 'img[appHideMissing]',
})
export class HideMissingDirective {
  constructor(private el: ElementRef) {}

  @HostListener('error')
  private onError() {
    if (environment.debug) {
      // console.log('404: ' + this.el.nativeElement.src);
    }

    this.el.nativeElement.src =
      environment.siteUrl + `/assets/images/default.jpg`;
  }
}
