import { Component, OnInit } from '@angular/core';
import { ProductService } from '@app/services/product.service';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit {

  public keepOriginalOrder = (a: { key: any; }, b: any) => a.key

  headers: any;
  data: any;
  errors: any;

  constructor(
    public productService: ProductService
  ) { }

  //load all data from productservice on load 
  ngOnInit(): void {
    this.productService.data$.subscribe(
      result => {
        this.data = result.data;
        this.headers = result.headers;
        this.errors = result.errors;
      }
    )
  }

  hasErrors(i: number) {
    let found = false;
    this.errors.forEach((e: any) => {
      if (e.row == i) {
        found = true;
      }
    });
    return found;
  }

}
