import { Injectable } from '@angular/core';
// import jspdf from 'jspdf';
import jspdf from 'jspdf';


@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  constructor() { }


  getContentHeight(headerHeight: any) {
    287 - (headerHeight + 8)
  }


  drawHeader(doc: jspdf,
    dummyContent: any,
    pageMargin: any,
    startYForText: any,
    pageWidth: any,
    isFirstPage: boolean = true
  ) {

    if (isFirstPage === true) {
      doc.setFillColor("#4aa5b4");
      doc.rect(0, 0, 210, 40, "F");
      doc.setFontSize(9.5);
      doc.setTextColor("#FFFFFF");
      var comments = new Image();
      comments.src = 'assets/comments-white.png';
      doc.addImage(comments, 'PNG', pageMargin, startYForText + 1.5, 4.38, 3.5);
      doc.text(dummyContent.number, pageMargin + 6, startYForText + 4.7);

      var plane = new Image();
      plane.src = 'assets/plane-white.png';
      doc.addImage(plane, 'PNG', pageMargin + 36, startYForText + 1.5, 3.5, 3.5);
      doc.text(dummyContent.email, pageMargin + 41, startYForText + 4.7);

      var location = new Image();
      location.src = 'assets/location-white.png';
      doc.addImage(location, 'PNG', pageMargin, startYForText + (doc.getTextDimensions(dummyContent.email).h + 5), 2.6, 3.5);

      doc.setFont('Rubik-light', 'light');
      doc.text(dummyContent.addressfirst, pageMargin + 5, startYForText + (doc.getTextDimensions(dummyContent.email).h + 8));

      doc.text(dummyContent.addresssecond, pageMargin, startYForText + ((doc.getTextDimensions(dummyContent.addressfirst).h + 7 + doc.getTextDimensions(dummyContent.email).h) + 5));

      doc.setFont('Rubik-normal', 'normal');
      doc.setFillColor("#000000");
      doc.rect(0, 0, pageWidth / 2, 12, "F");
      doc.setFontSize(14);
      doc.text(dummyContent.title, pageMargin, 8);
      doc.setFillColor("#000000");
      doc.triangle(pageWidth / 2, 0, pageWidth / 2, 12, (pageWidth / 2) + 10, 0, "FD");

      var img = new Image();
      img.src = 'assets/dbm-monotone.png';
      doc.addImage(img, 'PNG', pageWidth - (87), 3, 77, 30);
    }

    else {
      doc.setFillColor("#4aa5b4");
      doc.rect(0, 0, 210, 10, "F");
      doc.setFontSize(12.5);
      doc.setTextColor("#FFFFFF");

      doc.text("Other Information", pageMargin, 6);

      doc.setFillColor("#000000");
      doc.rect(pageWidth / 2.5, 0, 210, 10, "F");
      doc.triangle(pageWidth / 2.5, 0, pageWidth / 2.5, 10, pageWidth / 2.5 - 10, 10, "F");

      //requires other logo
    }
  }

  drawFooter(doc: jspdf,
    pageHeight: any,
    pageWidth: any
  ) {
    doc.setFillColor("#000000");
    doc.setFontSize(8.5)
    doc.rect(0, pageHeight - 8, pageWidth, 8, "F");
    doc.setTextColor("#4aa5b4");
    doc.text("www.dbm-ltd.co.uk", pageWidth - 30, pageHeight - 4);
    doc.setTextColor("#FFFFFF");
    var pageCount = doc.getNumberOfPages();
    doc.text(doc.getCurrentPageInfo().pageNumber + "/" + pageCount, pageWidth / 2, pageHeight - 4)
  }

}
