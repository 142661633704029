import { Injectable, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject, of } from 'rxjs';
import { debounceTime, delay, map, switchMap, tap } from 'rxjs/operators';

import { environment } from '@env/environment';

import { Catalog } from '@models/catalog';
import { DecimalPipe } from '@angular/common';

import { Papa } from 'ngx-papaparse';
export interface FilterItem {
  type: string;
  filteredCount: number;
  values: any[];
}
interface SearchResult {
  products: any[];
  total: number;
}

interface State {
  searchTerm: string;
  filter: [];
}

enum FilterType {
  SELECT = 'select',
}
interface Filter {
  title: string;
  key: string;
  type: FilterType.SELECT;
  terms: FilterTerm[];
  multi: boolean;
  placeholder: string;
  view?: string;
}

interface FilterTerm {
  value: string;
  count: number;
  selected: boolean;
}

function matches(product: any, term: string, pipe: PipeTransform) {
  return product['search'].toLowerCase().includes(term.toLowerCase());
  // || pipe.transform(country.area).includes(term)
  // || pipe.transform(product.post_title).includes(term);
}

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _filtering$ = new BehaviorSubject<boolean>(true);
  public _search$ = new Subject<void>();
  private _products$ = new BehaviorSubject<any[]>([]);
  private _data$ = new BehaviorSubject<any>({});
  private _total$ = new BehaviorSubject<number>(0);

  private _filterSource$: any[] = [];
  private _filter$ = new BehaviorSubject<any>(this._filterSource$);

  private _state: State = {
    searchTerm: '',
    filter: [],
  };

  public csv_data: any;
  public headers: any;
  public data: any;

  constructor(
    private http: HttpClient,
    private papa: Papa,
    private pipe: DecimalPipe
  ) {
    //subscribe to the result of getting csv data
    this.getCatalog().subscribe((catalog: any) => {
      //set the csv_data property to the csv data part of the catalog
      this.csv_data = catalog.csv_data;
      //parsing csv data
      this.papa.parse(this.csv_data.toString(), {
        complete: (result) => {
          this.headers = result.data[0];
        },
      });

      this.papa.parse(this.csv_data.toString(), {
        header: true,
        complete: (result) => {
          let p: any[] = [];

          result.data.forEach((obj: any) => {
            obj['Search'] =
              obj['ProductRecord.AccountReference'] +
              ' ' +
              obj['Supplier'] +
              ' ' +
              obj['Product Category'] +
              ' ' +
              obj['Footprint'] +
              ' ' +
              obj['Structure'] +
              ' ';

            // update object keys
            obj = Object.fromEntries(
              Object.entries(obj).map(([k, v]) => [
                k
                  .toLowerCase()
                  .replace(/[,.\- ]/g, '_')
                  .replace(/[\])}[{(]/g, ''),
                v,
              ])
            );

            if (this.productExists(p, obj.web_product_code)) {
              let existing = p.find((e) => e.code === obj.web_product_code);
              existing.variants.push(this.stripFields(obj));
            } else {
              // p.push({
              //   code: obj.web_product_code,
              //   image:
              //     'https://dbm-catalog.daysix.co/assets/images/' +
              //     obj.web_product_code.replace(/\?.*$/g, '') +
              //     '.jpg',
              //   productrecord_supplieraccountreference:
              //     obj.productrecord_supplieraccountreference,
              //   product_category: obj.product_category,
              //   web_product_code: obj.web_product_code,
              //   footprint: obj.footprint,
              //   length_mm: obj.length_mm,
              //   width_mm: obj.width_mm,
              //   layout: obj.layout,
              //   depth_mm: obj.depth_mm,
              //   cavities: obj.cavities,
              //   structure: obj.structure,
              //   divide: obj.divide,
              //   material: ['ECOPET', 'ECOPET/PE', 'RPET', 'RPET/PE'],
              //   colour: ['Any'],
              //   variants: [this.stripFields(obj)],
              //   search: obj.search,
              // });
              // for all these if there is no value, set it to 'n/a'
              p.push({
                code: obj.web_product_code,
                image:
                  environment.siteUrl +
                  '/assets/images/' +
                  obj.web_product_code.replace(/\?.*$/g, '') +
                  '.jpg',
                productrecord_supplieraccountreference:
                  obj.productrecord_supplieraccountreference,
                product_category: obj.product_category,
                web_product_code: obj.web_product_code,
                footprint: obj.footprint,
                length_mm: obj.length_mm ? obj.length_mm : 'n/a',
                width_mm: obj.width_mm ? obj.width_mm : 'n/a',
                layout: obj.layout ? obj.layout : 'n/a',
                depth_mm: obj.depth_mm ? obj.depth_mm : 'n/a',
                cavities: obj.cavities ? obj.cavities : 'n/a',
                structure: obj.structure ? obj.structure : 'n/a',
                divide: obj.divide ? obj.divide : 'n/a',
                material: obj.material ? [obj.material] : ['n/a'],
                colour: obj.colour ? [obj.colour] : ['n/a'],
                variants: [this.stripFields(obj)],
                search: obj.search,
              });
            }
          });

          this._products$.next(p);

          this.data = p;

          this._filterSource$ = this.buildFilter(p, 'trays');

          this._search$
            .pipe(
              tap(() => this._loading$.next(true)),
              debounceTime(100),
              switchMap(() => this._search(this.data, this._filterSource$)),
              delay(0),
              tap(() => {
                this._loading$.next(false);
                this._filtering$.next(true);
              })
            )
            .subscribe((result) => {
              this._products$.next(result.products);
              this._total$.next(result.products.length);
            });

          this._data$.next({
            headers: this.headers,
            data: this.data.data,
            errors: this.data.errors,
          });

          this._search$.next();
        },
      });
    });
  }

  getProductById(id: string): Observable<any> {
    return this._products$.pipe(
      map((products) => products.find((product) => product.code === id))
    );
  }

  productExists(arr: any[], code: string) {
    return arr.some(function (el) {
      return el['code'] === code;
    });
  }

  stripFields(obj: any) {
    delete obj.productrecord_supplieraccountreference;
    delete obj.product_category;
    delete obj.web_product_code;
    delete obj.footprint;
    delete obj.length_mm;
    delete obj.width_mm;
    delete obj.layout;
    delete obj.depth_mm;
    delete obj.cavities;
    delete obj.structure;
    delete obj.divide;
    return obj;
  }

  get products$() {
    return this._products$.asObservable();
  }
  get data$() {
    return this._data$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get filter$() {
    return this._filter$.asObservable();
  }

  get filter() {
    return this._state.filter;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }

  set searchTerm(searchTerm: string) {
    console.log('setting filter');
    this._set({ searchTerm });
  }

  set filter(filter: any) {
    console.log('setting filter');
    this._set({ filter });
  }

  private _set(patch: Partial<State>) {
    console.log('setting filter');
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(products: any, filters: any): Observable<SearchResult> {
    const { searchTerm, filter } = this._state;

    let total: number = products.length;

    let p: any[] = [];

    this._filterSource$.forEach((f) => {
      f.terms.forEach((t: any) => {
        if (t.selected === true) {
          products = products.filter((product: any) => {
            if (product[f.key] === t.value) {
              return product;
            }
          });
        }
      });
    });

    if (this._state.searchTerm) {
      products = products.filter((product: any) =>
        matches(product, searchTerm, this.pipe)
      );
    }

    this._filterSource$.forEach((f) => {
      f.terms.forEach((t: any) => {
        t.filteredCount = 0;
        products.forEach((p: any) => {
          if (p[f.key] === t.value) {
            t.filteredCount++;
          }
        });
      });
    });

    return of({ products, total });
  }

  //this function gets the whole catalogue object and returns it as an Observable of type Catalog
  public getCatalog(type?: string): Observable<Catalog> {
    return this.http.get<any>(environment.apiUrl + '/catalog/');
  }

  public filterChanged() {
    this._search$.next();
  }

  private buildFilter(data: any, type: string) {
    let filters: Array<Filter> = [];

    filters = [
      {
        title: 'Footprint',
        key: 'footprint',
        type: FilterType.SELECT,
        terms: [],
        multi: false,
        view: 'drop-down',
        placeholder: 'All',
      },
      {
        title: 'Layout',
        key: 'layout',
        type: FilterType.SELECT,
        terms: [],
        multi: true,
        view: 'pill',
        placeholder: '',
      },
      {
        title: 'Cavities',
        key: 'cavities',
        type: FilterType.SELECT,
        terms: [],
        multi: false,
        view: 'list',
        placeholder: 'All',
      },
      {
        title: 'Structure',
        key: 'structure',
        type: FilterType.SELECT,
        terms: [],
        multi: false,
        view: 'drop-down',
        placeholder: 'All',
      },
      {
        title: 'Divide Options',
        key: 'divide',
        type: FilterType.SELECT,
        terms: [],
        multi: true,
        view: 'drop-down',
        placeholder: 'All',
      },
    ];

    filters.forEach((e) => {
      var property = e.key;

      data.forEach((d: any) => {
        if (d[property] && !e.terms.some((obj) => obj.value === d[property])) {
          e.terms.push({
            value: d[property],
            selected: false,
            count: 0,
          });
        }
      });
    });

    this._filter$.next(filters);

    return filters;
  }

  public clearFilter(type: string) {
    this._filterSource$.forEach((e) => {
      e.terms.forEach((t: any) => {
        t.selected = false;
      });
    });
    this._filter$.next(this._filterSource$);
    this._search$.next();
  }
}
