<app-navigation></app-navigation>

<header>
  <div
    class="container-xl d-flex flex-wrap justify-content-center justify-content-md-between align-items-center h-100"
  >
    <h2 class="d-none d-md-inline-block text-white">
      {{ product ? product.product_code : "" }}
    </h2>
    <div class="position-relative d-flex"></div>
  </div>
</header>

<div class="container-xl py-5">
  <div *ngIf="!product" class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>

  <div *ngIf="product && type !== 'hygiene'">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['../../']">Catalog</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/trays']">Packaging</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ product.code }}
        </li>
      </ol>
    </nav>
    <div class="d-flex flex-column">
      <div class="d-flex flex-wrap gap-5">
        <div class="col">
          <img
            [src]="product.image"
            style="min-width: 300px"
            appHideMissing
            class="border border-dark rounded-4 p-3 img-fluid"
          />
        </div>
        <div class="col">
          <h1>{{ product.code }}</h1>
          <h4 class="text-primary primary mt-3 mb-3">In Stock</h4>
          <button
            [disabled]="productService.loading$ | async"
            class="btn btn-primary btn-sm rounded-pill text-white"
            (click)="downloadAsPDF()"
          >
            <fa-icon [icon]="faDownload" class="text-white"> </fa-icon>
            Download Factsheet
          </button>
          <div class="productDetailRow">
            <span>Category:</span>
            <span class="text-primary"> {{ product.product_category }}</span>
          </div>
          <div class="productDetailRow">
            <span>Footprint:</span>
            <span class="text-primary"> {{ product.footprint }}</span>
          </div>
          <div class="productDetailRow">
            <span>Length (mm):</span>
            <span class="text-primary"> {{ product.length_mm }}</span>
          </div>
          <div class="productDetailRow">
            <span>Width (mm):</span>
            <span class="text-primary"> {{ product.width_mm }}</span>
          </div>
          <div class="productDetailRow" *ngIf="product.layout">
            <span>Layout:</span>
            <span class="text-primary"> {{ product.layout }}</span>
          </div>
          <div class="productDetailRow">
            <span>Depth (mm):</span>
            <span class="text-primary"> {{ product.depth_mm }}</span>
          </div>
          <div class="productDetailRow">
            <span>Cavities:</span>
            <span class="text-primary"> {{ product.cavities }}</span>
          </div>
          <div class="productDetailRow">
            <span>Structure:</span>
            <span class="text-primary"> {{ product.structure }}</span>
          </div>
          <div class="productDetailRow">
            <span>Divide:</span>
            <span class="text-primary"> {{ product.divide }}</span>
          </div>
          <div class="productDetailRow">
            <span>Material:</span>
            <div class="text-end">
              <ng-container
                *ngFor="let material of product.material; last as isLast"
              >
                <span class="text-primary">{{ material }}</span
                ><ng-container *ngIf="!isLast">, </ng-container>
              </ng-container>
            </div>
          </div>
          <div class="productDetailRow">
            <span>Colours:</span>
            <div>
              <ng-container
                *ngFor="let colour of product.colour; last as isLast"
              >
                <span class="text-primary"> {{ colour | titlecase }}</span
                ><ng-container *ngIf="!isLast">,</ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ng-container *ngIf="product.variants">
          <h4 class="mt-5 mb-3">In Stock</h4>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Product Ref</th>
                <th scope="col">Material</th>
                <th scope="col">Micron</th>
                <th scope="col">Colour</th>
                <!-- <th scope="col">Status</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let variant of product.variants">
                <th scope="row">
                  {{ variant.productrecord_accountreference }}
                </th>
                <td class="text-primary">{{ variant.material }}</td>
                <td class="text-primary">{{ variant.micron }}</td>
                <td class="text-primary">
                  {{ variant.colour | titlecase }}
                </td>
                <!-- <td>{{ variant.status }}</td> -->
              </tr>
            </tbody>
          </table>
        </ng-container>
      </div>
    </div>

    <!-- <pre>{{ product | json }}</pre> -->
  </div>
  <div *ngIf="type === 'hygiene' && product" class="container-xxl">
    <ng-container>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['../../']">Catalog</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/hygiene']">Hygiene</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ product.daysix_product_code }}
          </li>
        </ol>
      </nav>

      <div *ngIf="product" class="mainContainer row">
        <div
          class="thumbnails d-flex flex-wrap d-sm-block col-12 col-sm-2 col-lg-1"
        >
          <div class="mb-1" *ngFor="let variant of product.variants">
            <img
              [src]="
                variant.daysix_sub_product_code.length > 0
                  ? siteUrl +
                    '/assets/images/hygiene/' +
                    variant.daysix_sub_product_code.replace('/', '') +
                    '.jpg'
                  : siteUrl +
                    '/assets/images/hygiene/' +
                    variant.dbm_product_code.replace('/', '') +
                    '.jpg'
              "
              style="border-color: #c7c8c9"
              [class.border-dark]="variant.selected"
              appHideMissing
              class="border rounded-4 img-fluid"
              (click)="setColourway(variant)"
            />
          </div>
        </div>
        <div class="col-12 col-sm-5 col-lg-6">
          <img
            [src]="product.image"
            class="border rounded-4 p-3 img-fluid"
            style="border-color: #c7c8c9; width: 100%"
            appHideMissing
          />
        </div>
        <div class="details col-12 col-sm-5">
          <p>
            {{ product.product_group }}
          </p>
          <h1>{{ product.name }}</h1>
          <h4 class="primary mt-3 mb-3">In Stock</h4>
          <hr />
          <p>
            Category:
            <span class="primary"> {{ product.category }}</span>
          </p>
          <p>
            Product Code:
            <span class="primary"> {{ product.product_code }}</span>
          </p>
          <hr />
          <p>{{ product.short_description }}</p>
          <hr *ngIf="product.short_description" />
          <p>{{ product.detailed_description }}</p>

          <ng-container class="w-50">
            <div class="d-flex flex-column">
              <div
                *ngIf="
                  product.variants[0].unit_of_sale !== '' &&
                  product.variants[0].unit_of_sale !== 'Each'
                "
              >
                <p>Unit of Sale:</p>
                <div class="d-flex gap-3">
                  <button
                    (click)="setUnit(unit)"
                    class="sizeBtn"
                    *ngFor="let unit of availableUnits"
                  >
                    {{ unit }}
                  </button>
                </div>
              </div>
              <p *ngIf="product.variants[0].colour !== ''">
                Colour:
                <span class="text-primary">{{ currentVariant.colour }}</span>
              </p>
              <div class="d-flex gap-3 p-2">
                <div *ngFor="let variant of colourVariants">
                  <div
                    *ngIf="variant.colour"
                    [ngClass]="variant.selected ? 'selected' : ''"
                    class="colourBtn {{
                      variant.colour
                        .toLowerCase()
                        .replace('/', '')
                        .replace(' ', '')
                    }}"
                    (click)="setColourway(variant)"
                  ></div>
                </div>
              </div>
            </div>
            <div *ngIf="sizes">
              <div
                *ngIf="sizes[0] !== ''"
                class="mt-3 d-flex gap-3 flex-column"
              >
                <span>
                  {{
                    sizes.length > 1 ? "Available Sizes:" : "Available Size:"
                  }}
                </span>
                <div class="d-flex gap-3">
                  <button class="sizeBtn" *ngFor="let size of sizes">
                    {{ size }}
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- <div class="row">
            <p class="mt-3">Variants:</p>
            <div class="d-flex gap-2 overflow-scroll p-2">
              <div *ngFor="let variant of product.variants">
                <img
                  [src]="
                    variant.daysix_sub_product_code.length > 0
                      ? 'https://dbm-catalog.daysix.co/assets/images/hygiene/' +
                        variant.daysix_sub_product_code +
                        '.jpg'
                      : 'https://dbm-catalog.daysix.co/assets/images/hygiene/' +
                        variant.product_code +
                        '.jpg'
                  "
                  style="
                    width: 200px;
                    margin-bottom: 10px;
                    border-color: #c7c8c9;
                    min-width: 200px;
                  "
                  [ngClass]="variant.selected ? 'shadow' : ''"
                  appHideMissing
                  class="indexImg border rounded-4 p-3 img-thumbnail"
                  (click)="setColourway(variant)"
                />
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </ng-container>
  </div>
</div>
