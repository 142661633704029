import { Injectable, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject, of } from 'rxjs';
import { debounceTime, delay, map, switchMap, tap } from 'rxjs/operators';

import { environment } from '@env/environment';

import { Catalog } from '@models/catalog';
import { DecimalPipe } from '@angular/common';

import { Papa } from 'ngx-papaparse';
export interface FilterItem {
  type: string;
  filteredCount: number;
  values: any[];
}
interface SearchResult {
  products: any[];
  total: number;
}

interface State {
  searchTerm: string;
  filter: [];
  page: number;
  pageSize: number;
}

enum FilterType {
  SELECT = 'select',
}
interface Filter {
  title: string;
  key: string;
  type: FilterType.SELECT;
  terms: FilterTerm[];
  multi: boolean;
  placeholder: string;
  view?: string;
}

interface FilterTerm {
  value: string;
  count: number;
  selected: boolean;
}

function matches(product: any, term: string, pipe: PipeTransform) {
  return product['search'].toLowerCase().includes(term.toLowerCase());
  // || pipe.transform(country.area).includes(term)
  // || pipe.transform(product.post_title).includes(term);
}

@Injectable({
  providedIn: 'root',
})
export class HygieneService {
  private _hygieneLoading$ = new BehaviorSubject<boolean>(true);
  private _hygieneSearching$ = new BehaviorSubject<boolean>(false);
  public _hygieneSearch$ = new Subject<void>();

  private _hygieneProductsSource: any[] = [];
  private _hygieneProducts$ = new BehaviorSubject<any[]>([]);
  private _hygieneData$ = new BehaviorSubject<any>({});
  private _hygieneTotal$ = new BehaviorSubject<number>(0);
  private _hygieneTotalFiltered$ = new BehaviorSubject<number>(0);

  private _hygieneFilterSource$: any[] = [];
  private _hygieneFilter$ = new BehaviorSubject<any>(
    this._hygieneFilterSource$
  );

  private _hygieneState: State = {
    searchTerm: '',
    filter: [],
    page: 1,
    pageSize: 50,
  };

  public hygiene_csv_data: any;
  public hygieneHeaders: any;
  public hygieneData: any;
  resultsInEachCategory: any;

  constructor(
    private http: HttpClient,
    private papa: Papa,
    private pipe: DecimalPipe
  ) {
    this.getHygieneCatalog().subscribe((catalog: any) => {
      //set the csv_data property to the csv data part of the catalog
      this.hygiene_csv_data = catalog.csv_data;
      //parsing csv data

      this.papa.parse(this.hygiene_csv_data.toString(), {
        header: true,
        complete: (result) => {
          this.hygieneHeaders = result.data[0];
          let p: any[] = [];
          result.data.forEach((obj: any) => {
            obj['Search'] =
              // obj['Daysix product code'] + ' ' + obj['Name'] + ' ';
              obj['DBM Product Code'] + ' ' + obj['Name'] + ' ';

            // update object keys
            obj = Object.fromEntries(
              Object.entries(obj).map(([k, v]) => [
                k
                  .toLowerCase()
                  .replace(/[,.\- ]/g, '_')
                  .replace(/[\])}[{(]/g, ''),
                v,
              ])
            );

            if (
              obj.daysix_product_code &&
              this.productExists(p, obj.daysix_product_code)
            ) {
              let existing = p.find((e) => e.code === obj.daysix_product_code);
              existing.variants.push(this.stripFields(obj));
            } else {
              p.push({
                code: obj.daysix_product_code,
                image:
                  obj.daysix_sub_product_code.length > 0
                    ? environment.siteUrl +
                      '/assets/images/hygiene/' +
                      obj.daysix_sub_product_code +
                      '.jpg'
                    : environment.siteUrl +
                      '/assets/images/hygiene/' +
                      obj.dbm_product_code.replace('/', '') +
                      '.jpg',
                // obj['productrecord_accountreference'].replaceAll('-', '_') + '.jpg',
                // productrecord_supplieraccountreference: obj.productrecord_supplieraccountreference,
                product_code: obj.dbm_product_code,
                name: obj.name,
                images: obj.images,
                detailed_description: obj.detailed_description,
                short_description: obj.short_description,
                product_code_web: obj.product_code_web,
                daysix_product_code: obj.daysix_product_code,
                daysix_sub_product_code: obj.daysix_sub_product_code,
                // product_group: obj.product_group
                product_group:
                  obj.product_group.toLowerCase() === 'ppe'
                    ? obj.product_group.toUpperCase()
                    : obj.product_group.charAt(0).toUpperCase() +
                      obj.product_group.slice(1),

                category: obj.category,
                sub_cat_1: obj.sub_cat_1,
                sub_cat_2: obj.sub_cat_2,
                sub_cat_3: obj.sub_cat_3,
                // sex: obj.sex,
                // if obj.sex is blank, set to 'Unisex'
                sex: obj.sex !== '' ? obj.sex : 'Unisex',
                colours: obj.colour,
                variants: [this.stripFields(obj)],
                search: obj.search,
              });
              // order p by category
              p.sort((a, b) => {
                if (a.category < b.category) {
                  return -1;
                }
                if (a.category > b.category) {
                  return 1;
                }
                return 0;
              });
            }
          });

          this._hygieneProductsSource = p;

          this.hygieneData = p;

          this._hygieneFilterSource$ = this.buildFilter(p, 'hygiene');

          this._hygieneSearch$
            .pipe(
              tap(() => this._hygieneLoading$.next(true)),
              debounceTime(100),
              switchMap(() =>
                this._hygieneSearch(
                  this.hygieneData,
                  this._hygieneFilterSource$
                )
              ),
              delay(0),
              tap(() => {
                this._hygieneLoading$.next(false);
                this._hygieneSearching$.next(false);
              })
            )
            .subscribe((result) => {
              this._hygieneProducts$.next(result.products);
              this._hygieneTotalFiltered$.next(result.total);
              this._hygieneTotal$.next(this._hygieneProductsSource.length);
            });

          this._hygieneData$.next({
            headers: this.hygieneHeaders,
            data: this.hygieneData.data,
            errors: this.hygieneData.errors,
          });

          this._hygieneProducts$.next(this._hygieneProductsSource);
          this._hygieneSearch$.next();
        },
      });
    });
  }

  getHygieneProductById(id: string): Observable<any> {
    return this._hygieneProducts$.pipe(
      map((products) => products.find((product) => product.code === id))
    );
  }

  productExists(arr: any[], code: string) {
    return arr.some(function (el) {
      return el['code'] === code;
    });
  }

  stripFields(obj: any) {
    delete obj.productrecord_supplieraccountreference;
    delete obj.product_category;
    delete obj.web_product_code;
    delete obj.footprint;
    delete obj.length_mm;
    delete obj.width_mm;
    delete obj.layout;
    delete obj.depth_mm;
    delete obj.cavities;
    delete obj.structure;
    delete obj.divide;
    return obj;
  }

  get hygieneProducts$() {
    return this._hygieneProducts$.asObservable();
  }
  get hygieneData$() {
    return this._hygieneData$.asObservable();
  }
  get hygieneTotal$() {
    return this._hygieneTotal$.asObservable();
  }

  get hygieneTotalFiltered$() {
    return this._hygieneTotalFiltered$.asObservable();
  }
  get hygieneLoading$() {
    return this._hygieneLoading$.asObservable();
  }
  get hygieneSearching$() {
    return this._hygieneSearching$.asObservable();
  }
  get hygieneFilter$() {
    return this._hygieneFilter$.asObservable();
  }

  get hygieneFilter() {
    return this._hygieneState.filter;
  }
  get hygieneSearchTerm() {
    return this._hygieneState.searchTerm;
  }

  get page() {
    return this._hygieneState.page;
  }

  get pageSize() {
    return this._hygieneState.pageSize;
  }

  set hygieneSearchTerm(searchTerm: string) {
    this._hygieneSearching$.next(true);

    if (searchTerm === '') {
      this._hygieneFilterSource$.forEach((f) => {
        if (f.key === 'product_group') {
          f.terms.forEach((t: any) => {
            if (
              t.value.toLowerCase().replace(' ', '-') ===
              localStorage.getItem('selectedTab')
            ) {
              t.selected = true;
            } else {
              t.selected = false;
            }
          });
        }
      });
    }
    this._hygieneSet({ searchTerm });
  }
  set hygieneFilter(filter: any) {
    this._hygieneSet({ filter });
  }
  set page(page: number) {
    if (page > 0) {
      this._hygieneSet({ page: page });
    } else {
      this._hygieneSet({ page: 1 });
    }
  }
  set pageSize(pageSize: number) {
    localStorage.setItem('pageSize', pageSize.toString());
    this._hygieneSet({ pageSize });
  }

  private _hygieneSet(patch: Partial<State>) {
    Object.assign(this._hygieneState, patch);
    this._hygieneSearch$.next();
  }

  private _hygieneSearch(
    products: any,
    filters: any
  ): Observable<SearchResult> {
    const { searchTerm, filter, pageSize, page } = this._hygieneState;

    // Finding out how many results are in each category
    this.resultsInEachCategory = [];
    this._hygieneFilterSource$.forEach((f) => {
      if (f.key === 'product_group') {
        f.terms.forEach((t: any) => {
          this.resultsInEachCategory.push({
            product_group: t.value,
            count: 0,
          });
        });
      }
    });
    if (searchTerm) {
      products = products.filter((product: any) =>
        matches(product, searchTerm, this.pipe)
      );
      this.resultsInEachCategory.forEach(
        (r: { product_group: any; count: number }) => {
          products.forEach((p: any) => {
            if (p.product_group === r.product_group) {
              r.count++;
            }
          });
        }
      );
    }

    let p: any[] = [];

    this._hygieneFilterSource$.forEach((f) => {
      f.terms.forEach((t: any) => {
        t.key = f.key;
        if (t.selected === true) {
          products = products.filter((product: any) => {
            if (product[f.key] === t.value) {
              return product;
            }
          });
        }
      });
    });

    if (this._hygieneState.searchTerm) {
      products = products.filter((product: any) =>
        matches(product, searchTerm, this.pipe)
      );
    }

    this._hygieneFilterSource$.forEach((f) => {
      f.terms.forEach((t: any) => {
        t.filteredCount = 0;
        products.forEach((p: any) => {
          if (p[f.key] === t.value) {
            t.filteredCount++;
          }
        });
      });
    });

    const filteredTotal: number = products.length;

    const productsPaginated = products.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );

    return of({ products: productsPaginated, total: filteredTotal });
  }

  public getHygieneCatalog(type?: string): Observable<Catalog> {
    return this.http.get<any>(environment.apiUrl + '/catalog/hygiene');
  }

  public filterChanged() {
    this._hygieneSearch$.next();
  }

  private buildFilter(data: any, type: string) {
    let filters: Array<Filter> = [];

    filters = [
      {
        title: 'Sex',
        key: 'sex',
        type: FilterType.SELECT,
        terms: [],
        multi: true,
        view: 'pill',
        placeholder: '',
      },
      {
        title: 'Product Group',
        key: 'product_group',
        type: FilterType.SELECT,
        terms: [],
        multi: false,
        view: 'list',
        placeholder: 'All',
      },
      {
        title: 'Category',
        key: 'category',
        type: FilterType.SELECT,
        terms: [],
        multi: false,
        view: 'list',
        placeholder: 'Select Category',
      },
      {
        title: 'Sub Category 1',
        key: 'sub_cat_1',
        type: FilterType.SELECT,
        terms: [],
        multi: false,
        view: 'list',
        placeholder: 'Select Sub Category',
      },
      {
        title: 'Sub Category 2',
        key: 'sub_cat_2',
        type: FilterType.SELECT,
        terms: [],
        multi: false,
        view: 'list',
        placeholder: 'Select Sub Category 2',
      },
      {
        title: 'Sub Category 3',
        key: 'sub_cat_3',
        type: FilterType.SELECT,
        terms: [],
        multi: false,
        view: 'list',
        placeholder: 'Select Sub Category 3',
      },
      {
        title: 'Colours',
        key: 'colours',
        type: FilterType.SELECT,
        terms: [],
        multi: true,
        view: 'pill',
        placeholder: '',
      },
    ];

    filters.forEach((e) => {
      var property = e.key;

      data.forEach((d: any) => {
        if (d[property] && !e.terms.some((obj) => obj.value === d[property])) {
          e.terms.push({
            value: d[property],
            selected: false,
            count: 0,
          });
        }
      });
    });

    this._hygieneFilter$.next(filters);
    return filters;
  }

  public clearFilter(type: string) {
    this._hygieneFilterSource$.forEach((e) => {
      if (e.key !== 'product_group') {
        e.terms.forEach((t: any) => {
          t.selected = false;
        });
      }
    });
    this._hygieneFilter$.next(this._hygieneFilterSource$);
    this._hygieneSearch$.next();
  }

  public clearSubFilters(filter: Filter) {
    this._hygieneFilterSource$.forEach((e) => {
      if (e.key !== filter.key) {
        e.terms.forEach((t: any) => {
          t.selected = false;
        });
      }
    });
    this._hygieneFilter$.next(this._hygieneFilterSource$);
    this._hygieneSearch$.next();
  }

  public setSelectedProductGroup(group: string) {
    // console.log(
    //   'setSelectedProductGroup: ' + group,
    //   this._hygieneFilterSource$
    // );
    this._hygieneFilterSource$.forEach((e: Filter) => {
      e.terms.forEach((t: any) => {
        t.selected = false;
      });
      if (e.key === 'product_group') {
        e.terms.forEach((term) => {
          if (term.value === group) {
            // console.log('found value to set');
            term.selected = true;
          }
        });

        this.filterChanged();
      }
    });
  }
}
