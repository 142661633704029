import { Injectable } from '@angular/core';
import { HelpersService } from './helpers.service';
import { FontService } from './font.service';

import jspdf from 'jspdf';

@Injectable({
  providedIn: 'root',
})
export class DatasheetService {
  constructor(
    private helpersService: HelpersService,
    private fontService: FontService
  ) {}

  create(product: any) {
    console.log('Generating PDF', product);
  }

  addFonts(doc: jspdf) {
    const fonts = this.fontService.get_fonts();

    fonts.forEach((font) => {
      doc.addFileToVFS(font.ttfName, font.base64);
      doc.addFont(font.ttfName, font.fontName, font.fontWeight);
    });
  }

  createPDF(product: any) {
    let doc = new jspdf('p', 'mm', 'a4');

    doc.setLineHeightFactor(1.5);
    this.addFonts(doc);
    doc.setFont('Rubik-normal', 'normal');

    //dimension of A4 paper in mm - 210 x 297 mm

    //x - coordinate (in the units declared at initialising pdf document) against left edge of the page
    //y - coordinate against upper edge of page

    var currentPageWidth = doc.internal.pageSize.width;
    var currentPageHeight = doc.internal.pageSize.height;

    var pageMargin = 10;
    var topMargin = 20;
    var contentPadding = 10;

    //width of content to fit inside page with margins
    // var contentWidth = currentPageWidth -= pageMargin * 2;
    // //height of content to fit inside page with top and bottom margins
    // var contentHeight = currentPageHeight -= topMargin * 2;

    var startX = pageMargin;
    var startY = 0;

    const dbmInfo = {
      title: 'Product Specifications Sheet',
      number: '0137464622222  |',
      email: 'sales@dbm-ltd.co.uk',
      addressfirst: 'East Mains Industrial Estate',
      addresssecond: '3 Clifton View, Broxburn EH52 5NE',
    };

    //header section for FIRST page

    this.helpersService.drawHeader(
      doc,
      dbmInfo,
      startX,
      15,
      currentPageWidth,
      true
    );

    this.helpersService.drawFooter(doc, 297, 210);

    doc.setTextColor('#4aa5b4');
    doc.setFontSize(24);

    startY += 40 + contentPadding + 5;

    doc.text(product.code.toUpperCase(), startX, startY);

    var textContentHeight =
      (doc.getFontSize() * doc.getLineHeightFactor()) / 2.835;

    doc.setTextColor('#000000');

    startY += textContentHeight + contentPadding;

    doc.setFontSize(10.5);

    doc.setFontSize(16.5);

    var productImg = new Image();
    // const imageLink = product.image;
    productImg.src = product.image;
    // productImg.src = '/assets/test.jpeg'; //needs to be replaced when CORS is figured out
    doc.addImage(
      productImg,
      'JPEG',
      currentPageWidth / 2 - currentPageWidth / 4,
      startY,
      currentPageWidth / 2,
      (currentPageWidth / 2) * 0.67
    );

    startY += (currentPageWidth / 2) * 0.67 + contentPadding + 20;

    doc.setFontSize(10.5);
    var lineHeightText =
      (doc.getFontSize() * doc.getLineHeightFactor()) / 2.835;

    const materialsArray = product.material;

    const materials = product.material.join(', ');

    const arrayKeys = [
      'Category',
      'Footprint',
      'Length(mm)',
      'Width(mm)',
      'Depth(mm)',
      'Layout',
      'Cavities',
      'Structure',
      'Divide',
      'Material',
      'Colour',
    ];

    const arrayValues = [
      product.product_category,
      product.footprint,
      product.length_mm,
      product.width_mm,
      product.depth_mm,
      product.layout,
      product.cavities,
      product.structure,
      product.divide,
      materials,
      product.colour,
    ];

    // //check content can fit within content height here or add new page
    var dataTextHeight = (10.5 * doc.getLineHeightFactor()) / 2.835 + 2;

    doc.setTextColor('#000000');
    doc.setFontSize(16.5);
    doc.text('Technical data', startX, startY);
    doc.setFontSize(10.5);

    doc.setTextColor('#4aa5b4');
    for (let i = 0; i < arrayKeys.length; i++) {
      doc.text(
        arrayKeys[i],
        startX,
        startY + contentPadding + dataTextHeight * i
      );
    }

    doc.setTextColor('#000000');
    for (let i = 0; i < arrayValues.length; i++) {
      doc.text(
        arrayValues[i],
        50,
        startY + contentPadding + dataTextHeight * i
      );
    }

    doc.setProperties({
      title: product.code,
    });
    //not saving
    setTimeout(function () {
      // doc.output('dataurlnewwindow'), 'test';
      doc.save(`${product.code}-spec.pdf`);
    }, 1000);
  }
}
