import { Component, OnInit } from '@angular/core';

import { ProductService } from '@app/services/product.service';

import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
@Component({
  selector: 'app-packaging',
  templateUrl: './packaging.component.html',
  styleUrls: ['./packaging.component.scss'],
})
export class PackagingComponent implements OnInit {
  faMagnifyingGlass = faMagnifyingGlass;

  products: any;

  constructor(public productService: ProductService) {}

  ngOnInit(): void {
    this.productService.products$.subscribe((result) => {
      this.products = result;
    });
  }
}
