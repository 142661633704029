<div class="tabs">
  <div
    class="tab"
    [class.selected]="tab.key === selected"
    *ngFor="let tab of tabs"
    (click)="handleTabChanged(tab)"
  >
    {{ tab.label }}
    <span [ngClass]="tab.key === selected ? 'text-white' : 'text-primary'"
      ><strong>{{ tab.count > 0 ? "(" + tab.count + ")" : "" }}</strong></span
    >
  </div>
</div>
