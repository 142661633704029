<app-navigation></app-navigation>

<header>
  <div
    class="container-xl d-flex flex-wrap justify-content-center justify-content-md-between align-items-center h-100"
  >
    <h2 class="d-none d-md-inline-block text-white">
      Our Food Packaging Products
    </h2>
    <div class="position-relative float-end">
      <input
        class="form-control rounded-pill form-control-lg w-100"
        type="text"
        placeholder="Search"
        aria-label=""
        name="searchTerm"
        [(ngModel)]="productService.searchTerm"
        style="padding-left: 50px"
      />

      <fa-icon
        [icon]="faMagnifyingGlass"
        class="position-absolute _text-primary"
        style="
          font-size: 1.1rem;
          top: -1px;
          margin-left: 20px;
          margin-top: 13px;
        "
      >
      </fa-icon>

      <span
        style="right: 12px; top: 9px"
        class="position-absolute"
        *ngIf="productService.loading$ | async"
      >
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </span>
    </div>
  </div>
</header>

<div class="container-xl">
  <div class="row flex-nowrap">
    <div
      class="col-auto col-md-3 col-xl-2 _px-sm-2 px-0 _bg-light"
      style="_border-right: 1px solid rgba(0, 0, 0, 0.125)"
    >
      <div
        class="d-flex flex-column align-items-center align-items-sm-start _px-4 _pt-4 _text-white min-vh-100"
      >
        <app-filter
          class="d-none d-sm-block"
          [filter]="productService.filter$ | async"
          [type]="'tray'"
        ></app-filter>
      </div>
    </div>
    <div class="col py-5 px-5">
      <div
        *ngIf="productService.loading$ | async"
        class="spinner-border text-primary"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>

      <ng-container>
        <h4 class="text-secondary" *ngIf="!(productService.loading$ | async)">
          Showing
          {{ (productService.products$ | async)?.length }} product<ng-container
            *ngIf="(productService.products$ | async)?.length !== 1"
            >s</ng-container
          >

          <!-- <button class="float-end btn btn-primary text-white _w-100 mb-4" (click)="productService.clearFilter()">Clear
            Filter</button> -->
        </h4>

        <div>
          <ng-container *ngFor="let filter of productService.filter$ | async">
            <ng-container *ngFor="let term of filter.terms">
              <span
                class="badge rounded-pill bg-primary me-2 p-2 px-2 ps-3"
                *ngIf="term.selected"
                >{{ term.value
                }}<span
                  style="background-color: white; padding: 3px 4px"
                  class="ms-2 text-primary d-inline-block ms-2 rounded-circle cursor"
                  (click)="
                    term.selected = !term.selected;
                    productService.filterChanged()
                  "
                  >X</span
                ></span
              >
            </ng-container>
          </ng-container>
        </div>

        <div class="row row-cols-1 row-cols-md-3 g-5d mt-4">
          <div *ngFor="let product of products" class="col mb-5">
            <div
              class="card h-100 cursor"
              [routerLink]="['/product/tray/', product.code]"
            >
              <div
                class="card-body p-4 d-flex flex-column justify-content-between"
              >
                <div>
                  <h5 class="card-title text-primary text-center">
                    {{ product.code }}
                  </h5>

                  <img
                    [src]="product['image']"
                    class="px-3 py-5 w-100"
                    appHideMissing
                  />
                </div>

                <div class="_ps-4">
                  <small>
                    <ul class="list-unstyled mt-3">
                      <li>
                        Footprint:
                        <span class="text-primary float-end">{{
                          product["footprint"]
                        }}</span>
                        <hr />
                      </li>
                      <li>
                        Dimensions (WxLxD):
                        <span class="text-primary float-end"
                          >{{ product["length_mm"] }} x
                          {{ product["width_mm"] }} x
                          {{ product["depth_mm"] }}</span
                        >
                        <hr />
                      </li>
                      <li *ngIf="product['micron']">
                        Micron:
                        <span class="text-primary float-end">{{
                          product["micron"]
                        }}</span>
                        <hr />
                      </li>
                      <li *ngIf="product['structure']">
                        Structure:
                        <span class="text-primary float-end">{{
                          product["structure"]
                        }}</span>
                        <hr />
                      </li>
                      <li *ngIf="product['divide']">
                        Divide:
                        <span class="text-primary float-end">{{
                          product["divide"]
                        }}</span>
                        <hr />
                      </li>
                      <li *ngIf="product['cavities']">
                        Cavities:
                        <span class="text-primary float-end">{{
                          product["cavities"]
                        }}</span>
                        <hr />
                      </li>
                      <li *ngIf="product['layout']">
                        Layout:
                        <span class="text-primary float-end">{{
                          product["layout"]
                        }}</span>
                        <hr />
                      </li>
                      <li>
                        Material:
                        <span class="text-primary float-end">{{
                          product["material"]
                        }}</span>
                      </li>
                    </ul>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="card border-0" *ngIf="!(productService.loading$ | async)">
        <div class="card-body bg-light text-center py-5">
          <h2 class="text-primary">Looking for something specific?</h2>
          <a
            href="mailto:packaging.sales@dbm-ltd.co.uk"
            class="btn btn-lg btn-primary text-white"
            >Contact Us</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
