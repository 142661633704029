<app-navigation></app-navigation>
<!-- <header
  class="py-3 p-md-5"
  style="
    background-image: url('http://dbm-ltd.co.uk/wp-content/uploads/2022/06/logistics.jpg');
  "
></header> -->
<div class="homeDiv">
  <div class="homeOptionDiv">
    <div>
      <a href="/trays"
        ><img
          src="https://dbm-ltd.co.uk/wp-content/uploads/2023/03/AdobeStock_305746832.jpg"
      /></a>
    </div>
    <h1 class="text-lg font-weight-bold">Packaging</h1>
  </div>
  <div class="homeOptionDiv">
    <div>
      <a href="/hygiene"
        ><img
          src="https://images.unsplash.com/photo-1574482620811-1aa16ffe3c82?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
      /></a>
    </div>
    <h1 class="text-lg font-weight-bold">Hygiene</h1>
  </div>
</div>
