<!-- <button class="btn btn-lg btn-primary text-white w-100 mb-4" (click)="clear()">Clear Filter</button> -->

<div class="small p-3 mb-0">
  <ng-container *ngFor="let f of filter" [ngSwitch]="f['type']">
    <app-filter-select
      *ngSwitchCase="'select'"
      [title]="f['title']"
      [taxonomy]="f['key']"
      [terms]="f['terms']"
      [view]="f['view']"
      [multi]="f['multi']"
      [placeholder]="f['placeholder']"
      [options]="f['options']"
      (update)="update($event)"
    >
    </app-filter-select>

    <div *ngSwitchDefault>{{ f["title"] }}: Invalid type</div>
  </ng-container>
  <ng-container
    *ngIf="subFilter && checkFilteredCount()"
    [ngSwitch]="subFilter['type']"
  >
    <app-filter-select
      *ngSwitchCase="'select'"
      [title]="subFilter.title"
      [taxonomy]="subFilter.key"
      [terms]="subFilter.terms"
      [view]="subFilter.view"
      [multi]="subFilter.multi"
      [placeholder]="subFilter.placeholder"
      [options]="subFilter.options"
      (update)="update($event)"
    >
    </app-filter-select>
  </ng-container>
</div>

<!-- <small>
  <pre>{{ filter | json }}</pre>
</small> -->
