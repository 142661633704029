import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ProductService } from '@app/services/product.service';
import { HygieneService } from '@app/services/hygiene.service';
import { SubFilter } from '@app/models/subFilter';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input() type: string = '';
  @Input() filter: any[] | null = [];
  @Input() subFilter!: SubFilter;
  @Output() getSelection = new EventEmitter();

  constructor(
    private productService: ProductService,
    private hygieneService: HygieneService
  ) {}

  ngOnInit(): void {}

  update(option: any) {
    switch (this.type) {
      case 'hygiene':
        this.hygieneService._hygieneSearch$.next();
        break;
      case 'tray':
      default:
        this.productService._search$.next();
        break;
    }
    this.getSelection.emit(option);
  }

  clear() {
    this.productService.clearFilter(this.type);
    this.hygieneService.clearFilter(this.type);
  }
  checkFilteredCount() {
    return this.subFilter.terms.some((e) => e.filteredCount! > 0);
  }
}
