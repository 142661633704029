<nav class="navbar fixed-top navbar-light bg-light">
  <div class="container-xl navContainer">
    <img
      src="https://dbm-ltd.co.uk/wp-content/uploads/2022/06/logo2.png"
      class="logo cursor"
      [routerLink]="['/']"
    />
    <ul class="nav">
      <li class="nav-item">
        <a class="nav-link active" aria-current="page" [routerLink]="['/trays']"
          >Food Packaging</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/hygiene']">Food Hygiene</a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" [routerLink]="['/data']">Data</a>
      </li> -->
    </ul>
  </div>
</nav>
