import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, ParamMap } from '@angular/router';
import { ProductService } from '@services/product.service';
import { HygieneService } from '@app/services/hygiene.service';
import { faDownload, faTimes } from '@fortawesome/pro-light-svg-icons';
import { DatasheetService } from '@services/datasheet.service';
import { Product, Variant } from '@app/models/hygieneCatalog';

import { environment } from '@env/environment';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  siteUrl = environment.siteUrl;
  faDownload = faDownload;
  faTimes = faTimes;
  type: string | null = '';
  sku: string | null = '';
  product!: Product;
  content: string = 'test content';
  colourVariants!: Variant[];
  currentVariant!: Variant;
  sizes!: string[];
  availableUnits!: string[];
  product_code: string = '';
  constructor(
    private route: ActivatedRoute,
    public productService: ProductService,
    public hygieneService: HygieneService,
    private datasheetService: DatasheetService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.type = params.get('type');
      this.sku = params.get('id');
      if (this.sku && this.type === 'hygiene') {
        this.hygieneService.getHygieneProductById(this.sku).subscribe(
          (product) => {
            if (product) {
              this.product = product;
            }
            if (this.product) {
              this.currentVariant = this.product.variants[0];
              this.product.image =
                environment.siteUrl +
                `/assets/images/hygiene/${
                  this.currentVariant.daysix_sub_product_code.length > 0
                    ? this.currentVariant.daysix_sub_product_code.replace(
                        '/',
                        ''
                      )
                    : this.currentVariant.dbm_product_code.replace('/', '')
                }.jpg`;
              this.product.variants = this.product.variants.map((v) => {
                return { ...v, selected: false };
              });
              this.product.variants[0].selected = true;
              this.sizes = this.currentVariant.sizes.split(' ');
              this.colourVariants = this.product.variants.map((v) => {
                return {
                  ...v,
                  selected: v.colour === this.currentVariant.colour,
                };
              });

              this.availableUnits = this.product.variants
                .map((v) => v.unit_of_sale)
                .filter((v, i, a) => a.indexOf(v) === i);
              this.colourVariants = this.colourVariants.map((v, i) => {
                return { ...v, selected: i === 0 };
              });

              this.sizes = this.colourVariants[0].sizes.split(' ');
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } else if (this.sku && this.type !== 'hygiene') {
        this.productService.getProductById(this.sku).subscribe(
          (product) => {
            this.product = product;
            console.log(product);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }

  download() {
    this.datasheetService.create(this.product);
  }

  downloadAsPDF() {
    this.datasheetService.createPDF(this.product);
  }

  setColourway(variant: Variant) {
    this.product.image =
      environment.siteUrl +
      `/assets/images/hygiene/${
        variant.daysix_sub_product_code.length > 0
          ? variant.daysix_sub_product_code.replace('/', '')
          : variant.dbm_product_code.replace('/', '')
      }.jpg`;
    this.product.product_code = variant.dbm_product_code;
    this.product.short_description = variant.short_description;
    this.product.detailed_description = variant.detailed_description;

    this.product.variants = this.product.variants.map((v) => {
      return {
        ...v,
        selected: v.dbm_product_code === variant.dbm_product_code,
      };
    });
    this.sizes = variant.sizes.split(' ');

    this.colourVariants = this.colourVariants.map((v) => {
      return {
        ...v,
        selected: v.dbm_product_code === variant.dbm_product_code,
      };
    });

    this.currentVariant = variant;
  }
  setUnit(unit: string) {
    const variant = this.product.variants.find(
      (v) => v.unit_of_sale === unit.trim()
    );
    if (variant) {
      this.product.image =
        environment.siteUrl +
        `/assets/images/hygiene/${
          variant.daysix_sub_product_code.length > 0
            ? variant.daysix_sub_product_code.replace('/', '')
            : variant.dbm_product_code.replace('/', '')
        }.jpg`;

      this.product.product_code = variant.dbm_product_code;

      this.product.short_description = variant.short_description;
      this.product.detailed_description = variant.detailed_description;
      this.sizes = variant.sizes.split(' ');
      this.product.variants = this.product.variants.map((v) => {
        return {
          ...v,
          selected: v.dbm_product_code === variant.dbm_product_code,
        };
      });
      this.currentVariant = variant;
    }
  }

  // setSize(size: string) {
  //   console.log('here', this.product.variants);
  //   const variant = this.product.variants.find(
  //     (v) => v.size === size && v.colour === this.currentVariant.colour
  //   );
  //   if (variant) {
  //     console.log(variant.daysix_product_code);
  //     this.product.product_code = variant.product_code;
  //     this.product.description = variant.detailed_description;
  //     this.currentVariant = variant;
  //   }
  // }
}
